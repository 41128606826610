<template>
    <span v-if="inline" v-html="htmlContent"></span>
    <div v-else v-html="htmlContent"></div>
</template>

<script>
import { createApp } from 'vue';
import ContentHtmlLink from './ContentHtmlLink.vue';

export default {
    props: {
        htmlContent: {
            type: String,
            required: true,
        },
        siteId: {
            type: String,
            required: true,
        },
        inline: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    mounted() {
        const anchors = this.$el.getElementsByTagName('a'); // Not iterable

        Array.from(anchors).forEach((anchor) => {
            let url = anchor.getAttribute('href');

            // skip empty links
            if (url == null) return;

            // skip target_blank
            const target = anchor.getAttribute('target');
            if (target === '_blank') return;

            // make external links target_blank and skip
            if (url.startsWith('http') || url.startsWith('www.') || url.startsWith('mailto:') || url.startsWith('tel:')) {
                if (!target) {
                    anchor.setAttribute('target', '_blank');
                    console.log(
                        `Found external link to '${url}' and set target to '_blank'`
                    );
                }
                return;
            }

            // remove local-prefix
            const removeLocalPrefix = 'local://';
            if (url.startsWith(removeLocalPrefix)) {
                url = url.slice(removeLocalPrefix.length);
            }

            const path = url.includes('/') ? url : `/${this.siteId}/${url}`;

            console.log(
                `Found internal anchor tag and replaced '${url}' by '${path}'`
            );

            const propsData = {
                to: path,
                content: anchor.innerHTML,
                routerPush: this.$router.push,
            };
            createApp(ContentHtmlLink, propsData).mount(anchor);
        });
    },
};
</script>

<style>
div.block-center {
    justify-content: center !important;
    display: flex;
    text-align: left;
}

span.nowrap {
    white-space: nowrap;
}

table.table-center {
    width: 100%;
}
table.table-center tr td:first-child,
.table-center tr td:last-child {
    vertical-align: top;
    width: 50%;
    padding: 0 5px;
}
table.table-center tr td:first-child {
    text-align: right;
}
.table-center tr td:last-child {
    text-align: left;
}
</style>
