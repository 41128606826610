<template>
    <BlockEditor v-model="model" class="block-editor" />
    <pre v-if="debug">{{ model }}</pre>
</template>

<script setup>
import { defineModel } from 'vue';
import BlockEditor from '../componentunits/BlockEditor.vue';

const model = defineModel();

const debug = false;
</script>

<style scoped>
.block-editor {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    margin-top: 1rem;
}
</style>
