<template>
    <div
        v-if="storeSite.isAdmin"
        class="editorcontainer"
        :class="{ 'bg-greyeditor': !transparent }"
    >
        <div v-if="!editing">
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-btn @click="edit">
                        {{ editgroup }}<v-icon icon="mdi-pencil"></v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <div :class="{ 'bg-greyeditor': transparent }">
                <v-row>
                    <v-col cols="12" class="text-right">
                        <v-btn v-if="!editOrder" @click="storeEditor.togglePreview">
                            <v-icon
                                v-if="storeEditor.showPreview"
                                icon="mdi-eye-off"
                            ></v-icon>
                            <v-icon v-else icon="mdi-eye"></v-icon>
                        </v-btn>
                        &nbsp;
                        <v-btn @click="save">
                            {{ editgroup }}<v-icon icon="mdi-check"></v-icon>
                        </v-btn>
                        <v-btn @click="cancel">
                            <v-icon icon="mdi-close"></v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <EditorOrder v-if="editOrder" />
                <EditorProperties v-else />
            </div>
            <v-row>
                <v-col></v-col>
            </v-row>
        </div>
        <div v-if="debug">
            <v-row>
                <v-col>
                    <pre>{{ page }}</pre>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import { useSiteStore } from '@/stores/SiteStore';
import { useEditorStore } from '@/stores/EditorStore';
import { ref, watch, computed } from 'vue';
import EditorProperties from './EditorProperties.vue';
import EditorOrder from './EditorOrder.vue';

const props = defineProps({
    page: {
        type: Object,
        required: true,
    },
    editableProperties: {
        type: Array,
        required: false,
    },
    editOrder: {
        type: String,
        required: false,
    },
    path: {
        type: Object,
        required: false,
    },
    editgroup: {
        type: String,
        required: false,
    },
    editOrderAdd: {
        type: Object,
        required: false,
    },
    editOrderRemove: {
        type: String,
        required: false,
    },
    transparent: {
        type: Boolean,
        required: false,
    },
});

const editables = computed(() => {
    if (props.editOrder) return null;
    return new Set(props.editableProperties);
});

const storeSite = useSiteStore();
const storeEditor = useEditorStore();

const editing = ref(false);

async function edit() {
    editing.value = await storeEditor.edit(
        props.page,
        props.path,
        editables.value,
        props.editOrder,
        props.editOrderAdd,
        props.editOrderRemove
    );
}

async function save() {
    await storeEditor.save(props.page, props.path, editables.value, props.editOrder);
    editing.value = false;
}

async function cancel() {
    await storeEditor.cancel();
    editing.value = false;
}

watch(
    () => storeEditor.getEditable,
    () =>
        (editing.value = storeEditor.isEditing(
            props.page,
            editables.value,
            props.editOrder
        ))
);

const debug = false;
</script>

<style scoped>
.editorcontainer {
    margin: 12px 0;
}
.bg-greyeditor {
    background-color: #eee !important;
    color: black;
}
</style>
