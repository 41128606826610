<template>
    <VueEditor
        v-model="model"
        :editorToolbar="customToolbar"
        :useCustomImageHandler="true"
        :customModules="customModulesForEditor"
        :editorOptions="editorSettings"
    />
</template>

<script setup>
import { VueEditor, Quill } from 'vue3-editor';
import { defineModel } from 'vue';
import htmlEditButton from 'quill-html-edit-button';

const model = defineModel();

const fonts = [
    'default',
    'arial',
    'Verdana',
    'Calibri',
    'Futura',
    'Helvetica',
    'Roboto',
    'Consolas',
    'monospace',
    'Drawingwithmarkers',
    //'Theos',
    'Rastanty-Cortez',
    'Callista',
    'Mr-De-Haviland',
    'Oranienbaum',
    'Antic-Didone',
    'Nesans-Semi-Bold',
];

const customToolbar = [
    [{ font: fonts }],
    [{ size: ['small', false, 'large', 'huge'] }, { header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
    ],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
];
const customModulesForEditor = [
    { alias: 'htmlEditButton', module: htmlEditButton },
];
const editorSettings = {
    modules: {
        htmlEditButton: {},
    },
    debug: 'error',
};

const FontAttributor = Quill.import('attributors/class/font');
FontAttributor.whitelist = fonts;
Quill.register(FontAttributor, true);
</script>

<style>
.ql-snow .ql-picker.ql-font .ql-picker-options .ql-picker-item {
    font-family: attr(data-value);
    &::before {
        content: attr(data-value) !important;
    }
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value) !important;
}
</style>
