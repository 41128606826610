<template>
    <div ref="editorHolder"></div>
</template>

<script setup>
import {
    defineProps,
    defineModel,
    onMounted,
    onUnmounted,
    ref,
    computed,
    watch,
} from 'vue';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import SimpleImage from '../editor/editorjsPlugins/simple-image';
import EditorjsList from '@editorjs/list';
import RawTool from '@editorjs/raw';
import CodeTool from '@editorjs/code';
import { useEditorStore } from '@/stores/EditorStore';

const props = defineProps({
    value: {
        type: Object,
        required: false,
    },
});

const storeEditor = useEditorStore();

const model = defineModel();
const editorHolder = ref(null);

const isReadonly = computed(() => model.value === undefined);

let editor;

function initEditor() {
    destroyEditor();
    editor = new EditorJS({
        holder: editorHolder.value,
        data: isReadonly.value ? props.value : model.value,
        tools: {
            header: {
                class: Header,
                inlineToolbar: true,
                config: {
                    placeholder: 'Enter a header',
                    levels: [1, 2, 3, 4],
                    defaultLevel: 1,
                },
            },
            paragraph: {
                class: Paragraph,
                inlineToolbar: true,
            },
            image: SimpleImage,
            code: CodeTool,
            raw: RawTool,
            list: {
                class: EditorjsList,
                inlineToolbar: true,
                config: {
                    defaultStyle: 'unordered',
                    maxLevel: 1,
                },
            },
        },
        logLevel: 'WARN',
        readOnly: isReadonly.value,
        minHeight: 30,
        onChange: () => {
            if (isReadonly.value) {
                console.log('No save because readonly');
                return;
            }

            editor
                .save()
                .then((outputData) => {
                    console.log('BlockEditor save: ', outputData);
                    cleanUpOutputDataBlocks(outputData.blocks);
                    model.value = outputData;
                })
                .catch((error) => {
                    console.error('BlockEditor Saving failed: ', error);
                });
        },
    });
}

function destroyEditor() {
    if (editor) {
        editor.destroy();
        editor = null;
    }
}

function cleanUpOutputDataBlocks(blocks) {
    blocks.forEach((block) => {
        if (block.type === 'list' && block.data.style === 'ordered' && block.data.meta && block.data.meta?.start === undefined) {
            block.data.meta.start = 1;
        } else if (block.type === 'list' && block.data.style === 'checklist') {
            block.data.style = 'unordered';
            block.data.items.forEach((item) => {
                item.meta = {};
            });
        } else if (block.type === 'raw') {
            block.data.html = storeEditor.formatHtml(block.data.html);
        }
    });
}

onMounted(() => {
    initEditor();
});
onUnmounted(() => {
    destroyEditor();
});

watch(
    () => props.value,
    () => {
        if (isReadonly.value) {
            initEditor();
        }
    }
);
</script>
