<template>
    <div class="objects pa-4">
        <h1>Objects</h1>
        <h2>NewData</h2>
        <v-textarea v-model="stringData" @input="writeNewData"></v-textarea>
        <pre>{{ newData }}</pre>
        <span>Pfad:</span><v-text-field v-model="path"></v-text-field>
        <span>ID:</span><v-text-field v-model="id"></v-text-field>
        <p><v-btn @click="updatePage">Update Page</v-btn></p>
        <p><v-btn @click="createPage">Create Page</v-btn></p>
        <h2>Page</h2>
        <span>Site</span><v-text-field v-model="site"></v-text-field>
        <p><v-btn @click="loadPage">Load Page</v-btn></p>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useBaseStore } from '@/stores/BaseStore';
import {
    collection,
    addDoc,
    doc,
    updateDoc,
    getDoc,
    setDoc,
} from 'firebase/firestore';

const storeBase = useBaseStore();

const pagesRef = computed(() => collection(storeBase.db, path.value));
const pageRef = computed(() => doc(pagesRef.value, id.value));
const pageLoadRef = computed(() => doc(storeBase.db, site.value));

const stringData = ref('');
const path = ref('/sites/hochzeit-mirjam-philipp/pages');
const id = ref('test');
const site = ref('/sites/hochzeit-bsc-yb/pages/anmeldung');

function writeNewData() {
    try {
        const j = JSON.parse(stringData.value);
        newData = j;
    } catch {
        /* empty */
    }
}

let newData = {
    order: 1,
    name: 'Hamam',
    price: 120,
    link: 'https://www.aquabasilea.ch/hamam.html',
    image: 'https://cdn.freizeit.ch/media/cache/default_landscape/default/0001/22/21704_default_landscape.jpg',
    content: '<p><strong>Hamam</strong> Tageseintritt</p><p>Aquabasilea</p>',
    present: true,
    presentOnly: false,
    disabled: false,
    given: 0,
};

// const newData = {
//     form: {
//         fields: [
//             {
//                 shareField: false,
//                 name: 'anmeldung',
//                 type: 'literal',
//                 label: 'Anmeldung für:',
//             },
//             {
//                 value: false,
//                 name: 'trauung',
//                 type: 'checkbox',
//                 label: 'Trauung & Apéro',
//                 shareField: false,
//             },
//             {
//                 value: false,
//                 shareField: false,
//                 name: 'abend',
//                 label: 'Abend',
//                 type: 'checkbox',
//             },
//             {
//                 label: 'Ich melde mich ab',
//                 shareField: false,
//                 type: 'checkbox',
//                 value: false,
//                 name: 'abmeldung',
//             },
//             {
//                 shareField: false,
//                 label: 'Vor- und Nachname',
//                 type: 'text',
//                 value: '',
//                 name: 'name',
//                 rules: ['required Name', 'min 2'],
//             },
//             {
//                 label: 'E-Mail',
//                 value: '',
//                 name: 'email',
//                 type: 'text',
//                 shareField: true,
//                 rules: ['required E-Mail', 'mail E-Mail'],
//             },
//             {
//                 label: 'Telefon',
//                 value: '',
//                 shareField: true,
//                 name: 'phone',
//                 rules: ['phone Telefon'],
//                 type: 'text',
//             },
//             {
//                 value: false,
//                 shareField: false,
//                 name: 'person2',
//                 label: '+1',
//                 type: 'checkbox',
//             },
//             {
//                 name: 'name2',
//                 rules: ['requiredIf Name+1 person2'],
//                 type: 'text',
//                 label: 'Vor- und Nachname +1',
//                 value: '',
//                 shareField: false,
//             },
//             {
//                 shareField: false,
//                 type: 'literal',
//                 name: 'tanzkurs',
//                 label: 'Ich nehme am Tanzkurs teil:',
//             },
//             {
//                 value: false,
//                 type: 'checkbox',
//                 name: 'tanzkursbeide',
//                 label: '10. und 24. Juni',
//                 shareField: false,
//             },
//             {
//                 label: 'Nur 10. Juni',
//                 value: false,
//                 name: 'tanzkurs1',
//                 type: 'checkbox',
//                 shareField: false,
//             },
//             {
//                 name: 'tanzkurs2',
//                 value: false,
//                 type: 'checkbox',
//                 shareField: false,
//                 label: 'Nur 24. Juni',
//             },
//             {
//                 value: false,
//                 shareField: false,
//                 type: 'checkbox',
//                 label: 'Kein Tanzkurs',
//                 name: 'tanzkurskeine',
//             },
//             {
//                 name: 'lieblingslied',
//                 type: 'text',
//                 value: '',
//                 label: 'Lieblingslied',
//                 persistentHint: true,
//                 hint: 'Bei diesem Song findet man dich auf der Tanzfläche',
//                 shareFields: false,
//             },
//             {
//                 hint: 'Essgewohnheiten, Allergien, Unverträglichkeiten',
//                 value: '',
//                 type: 'textarea',
//                 persistentHint: true,
//                 label: 'Bemerkungen',
//                 name: 'bemerkungen',
//                 shareFields: false,
//             },
//         ],
//         sendBtnText: 'Anmelden',
//         mail: {
//             recipients: ['yannik@beimler.ch'],
//             text: '<p>Hallo %name%</p> <p>Vielen Dank für die An- bzw. Abmeldung</p> <p>Folgendes Formular ist bei uns erfolgreich angekommen:</p> <p>%fields%</p> <p>&nbsp;</p> <p>Wir freuen uns!</p> <p>Liebe Grüsse</p> <p>Carmen und Yannik</p>',
//             subject: 'Hochzeit Carmen Yannik - Anmeldung Abend',
//         },
//     },
// };

// const kennwood = {
//     order: 10,
//     name: 'Kenwood',
//     price: 1133,
//     link: 'https://www.galaxus.ch/de/s2/product/kenwood-chef-xl-kcl95424si-1500-w-kuechenmaschine-23133250',
//     image: 'https://www.galaxus.ch/im/Files/7/1/2/9/3/7/4/1/1328859_20210114_100040.png',
//     content:
//         '<p><strong>Kenwood</strong> Chef XL</p><p>Küchenmaschine KCL.95.424SI</p>',
//     present: true,
//     presentOnly: false,
//     disabled: false,
//     given: 0,
// };

// const steckenpferd = {
//     order: 15,
//     name: 'Steckenpferd',
//     price: 40,
//     image: 'https://www.galaxus.ch/im/Files/2/0/3/0/1/5/1/5/4151_steckenpferd_rocky-a.jpg?impolicy=ProductTileImage&resizeWidth=628&resizeHeight=628&cropWidth=628&cropHeight=628&resizeType=downsize&quality=high',
//     link: 'https://www.galaxus.ch/de/s5/product/small-foot-steckenpferd-rocky-berufsrollenspiele-8198679',
//     content: '<p><strong>Steckenpferd</strong></p>',
//     present: true,
//     disabled: false,
//     given: 0,
// };

// const frizbox = {
//     order: 20,
//     name: 'Nur Geschenk',
//     price: 80,
//     link: 'https://www.digitec.ch',
//     content: '<p><strong>Frizbox</strong></p>',
//     image: '.jpg',
//     present: true,
//     presentOnly: true,
//     disabled: false,
//     given: 0,
// };

// const nurgeld = {
//     order: 30,
//     name: 'Nur Geld',
//     price: 5000,
//     image: 'https://img.zeit.de/gesellschaft/2020-01/geld-wirtschaftsgeschichte-zahlungsmittel/wide__980x551',
//     content: '<p><strong>Geld</strong></p>',
//     present: false,
//     disabled: false,
//     given: 0,
// };

function loadPage() {
    getDoc(pageLoadRef.value)
        .then((doc) => {
            stringData.value = JSON.stringify(doc.data());
            writeNewData();
            console.log('loaded');
        })
        .catch((error) => {
            console.error('Fehler:', error);
        });
}

function updatePage() {
    updateDoc(pageRef.value, newData)
        .then(() => {
            console.log('updated');
        })
        .catch((error) => {
            console.error('Fehler:', error);
        });
}

function createPage() {
    if (id.value) {
        setDoc(pageRef.value, newData)
            .then(() => {
                console.log('created', id.value);
            })
            .catch((error) => {
                console.error('Fehler:', error);
            });
    } else {
        addDoc(pagesRef.value, newData)
            .then((ret) => {
                console.log('created', ret.id);
            })
            .catch((error) => {
                console.error('Fehler:', error);
            });
    }
}
</script>
