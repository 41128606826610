import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import {
    collection,
    onSnapshot,
    doc,
    addDoc,
    query,
    where,
    serverTimestamp,
    getDocs,
} from 'firebase/firestore';
import { useAuthStore } from './AuthStore';
import { useBaseStore } from './BaseStore';
import { useMailStore } from './MailStore';

export const useSiteStore = defineStore('site', () => {
    const globalAdmin = 'SFR6FS9eKfhDdj8M4EJOc9VOroL2'; // ybeimler@gmail.com

    /* Stores */
    const storeAuth = useAuthStore();
    const storeBase = useBaseStore();
    const storeMail = useMailStore();

    /* Form */
    const _formResultsCollectionRef = computed(() => {
        return collection(
            _pagesCollectionRef.value,
            pageRoute.value,
            'formResults'
        );
    });

    const errorFormResult = ref(null);

    function addFormResult(fields, redirect) {
        const fieldValues = fields
            .filter((field) => field.type !== 'literal')
            .map((field) => ({
                name: field.name,
                value: field.value,
            }));
        console.log('add form result', fieldValues);
        addDoc(_formResultsCollectionRef.value, {
            fields: fieldValues,
            date: serverTimestamp(),
        })
            .then(() => {
                console.log('form result added');
                if (_mailField.value)
                    storeMail.saveMail(_mailField.value, fields);
                if (redirect) redirect();
            })
            .catch((error) => {
                console.error('Error addFormResult:', error);
                errorFormResult.value = error;
            });
    }

    /* Get Site */
    async function getSiteByHost() {
        console.log('getSiteByHost', currentHost.value);
        const querySnapshot = await getDocs(
            query(
                _sitesCollectionRef.value,
                where('url', '==', currentHost.value)
            )
        );
        return querySnapshot.docs[0]?.id;
    }

    /* Load Site */
    const site = ref(null);
    const lastLoadedSiteRoute = ref(null);
    let _unsubscribeListenerSite = null;

    const loading = computed(
        () =>
            _loadingSite.value ||
            _loadingPagesAuthorized.value ||
            _loadingPagesPublic.value
    );

    const siteHasContent = computed(() => site.value?.data != null);

    const _loadingSite = ref(true);
    function loadSite() {
        if (siteRoute.value === undefined) {
            unloadSite();
            return;
        }
        if (site.value?.id === siteRoute.value) return;
        // prüfen ob URL stimmt
        getSiteByHost().then((siteId) => {
            if (siteId && siteId !== siteRoute.value) {
                // umleiten falls falsche URL
                console.log('redirect to', `/${siteId}`);
                storeBase.router.replace(`/${siteId}`);
            } else {
                console.log('load site', siteRoute.value);
                _loadingSite.value = true;
                site.value = { id: siteRoute.value, data: null };
                _unsubscribeSite();
                console.log('create listener site');
                _unsubscribeListenerSite = onSnapshot(
                    _siteDocRef.value,
                    (doc) => {
                        console.log('update site');
                        site.value = {
                            id: doc.id,
                            data: doc.data(),
                        };
                        _loadPages();
                        _loadingSite.value = false;
                        lastLoadedSiteRoute.value = doc.id;
                    },
                    (error) => {
                        console.warn('error while loading site:', error);
                    }
                );
            }
        });
    }

    function unloadSite() {
        console.log('unload site');
        _unsubscribePages();
        _unsubscribeSite();
        pagesAuthorized.value = [];
        pagesPublic.value = [];
        site.value = null;
    }

    const isAdmin = computed(() => {
        const a =
            site.value?.data?.admin === storeAuth.user?.uid ||
            storeAuth.user?.uid === globalAdmin;
        if (a) console.log('isAdmin: true');
        return a;
    });

    /* Load Pages */
    const pagesAuthorized = ref([]);
    const pagesPublic = ref([]);

    let _unsubscribeListenerPagesAuthorized = null;
    let _unsubscribeListenerPagesPublic = null;
    let _unsubscribeListenerAuthChanged = null;

    function _loadPages() {
        console.log('load pages');
        _loadPagesAuthorized();
        _loadPagesPublic();
    }

    const pages = computed(() => {
        return [
            ...pagesAuthorized.value,
            ...pagesPublic.value.filter(
                (page) => !pagesAuthorized.value.find((p) => p.id === page.id)
            ),
        ];
    });

    function _loadPagesAuthorized() {
        console.log('load pages authorized');
        _unsubscribeAuthChanged();
        console.log('create listener auth changed');
        _unsubscribeListenerAuthChanged = storeAuth.addAuthChangedListener(
            (_user) => {
                console.log(
                    'AuthStateChanged (_loadPagesAuthorized)',
                    _user?.email
                );
                if (_user) {
                    _getPagesForCurrentUser();
                } else {
                    _unsubscribePagesAuthorized();
                    pagesAuthorized.value = [];
                }
            }
        );
    }

    const _loadingPagesAuthorized = ref(false);
    function _getPagesForCurrentUser() {
        console.log('get Pages for current user');
        _loadingPagesAuthorized.value = true;
        _unsubscribePagesAuthorized();
        console.log('create listener pages authorized');
        _unsubscribeListenerPagesAuthorized = onSnapshot(
            _pagesCollectionQueryAuthorized.value,
            (querySnapshot) => {
                console.log('update pages authorized');
                const fbPages = [];
                querySnapshot.forEach((doc) => {
                    const page = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    fbPages.push(page);
                });
                pagesAuthorized.value = fbPages;
                _loadingPagesAuthorized.value = false;
            },
            (error) => {
                console.warn('error while loading pages authorized:', error);
            }
        );
    }

    const _loadingPagesPublic = ref(false);
    function _loadPagesPublic() {
        console.log('load pages public');
        _loadingPagesPublic.value = true;
        _unsubscribePagesPublic();
        console.log('create listener pages public');
        _unsubscribeListenerPagesPublic = onSnapshot(
            _pagesCollectionQueryPublic.value,
            (querySnapshot) => {
                console.log('update pages public');
                const fbPages = [];
                querySnapshot.forEach((doc) => {
                    const page = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    fbPages.push(page);
                });
                pagesPublic.value = fbPages;
                _loadingPagesPublic.value = false;
            },
            (error) => {
                console.warn('error while loading pages public:', error);
            }
        );
    }

    function _unsubscribePages() {
        _unsubscribePagesAuthorized();
        _unsubscribePagesPublic();
        _unsubscribeAuthChanged();
    }

    function _unsubscribeSite() {
        if (!_unsubscribeListenerSite) return;
        console.log('unsubscribe site');
        _unsubscribeListenerSite();
        _unsubscribeListenerSite = null;
    }

    function _unsubscribeAuthChanged() {
        if (!_unsubscribeListenerAuthChanged) return;
        console.log('unsubscribe auth changed');
        _unsubscribeListenerAuthChanged();
        _unsubscribeListenerAuthChanged = null;
        _unsubscribePagesAuthorized();
    }

    function _unsubscribePagesAuthorized() {
        if (!_unsubscribeListenerPagesAuthorized) return;
        console.log('unsubscribe pages authorized');
        _unsubscribeListenerPagesAuthorized();
        _unsubscribeListenerPagesAuthorized = null;
    }

    function _unsubscribePagesPublic() {
        if (!_unsubscribeListenerPagesPublic) return;
        console.log('unsubscribe pages public');
        _unsubscribeListenerPagesPublic();
        _unsubscribeListenerPagesPublic = null;
    }

    /* Metadata */
    const theme = computed(() => {
        return site.value?.data?.theme ?? {};
    });

    function isTextCenter(field, pageLocal) {
        return !!getMetadata('textCenter', field, pageLocal);
    }

    function isBlockEditor(field, pageLocal) {
        return getMetadata('editor', field, pageLocal) === 'block';
    }

    function getMetadata(property, field, pageLocal) {
        const getMetadataValue = _getMetadataValueFunction(property, field);

        // wenn keine lokale Page übergeben wird, wird die aktuelle Page als lokal verwendet
        if (!pageLocal?.id) pageLocal = pageCurrent.value;

        // wenn eine lokale Metadaten-Eigenschaft vorhanden ist, wird diese zurückgegeben
        const localMeta = getMetadataValue(pageLocal?.data?.metadata, false);
        if (localMeta !== undefined) return localMeta;

        // wenn die lokale Page nicht der aktuellen Page entspricht, wird die Metadaten-Eigenschaft der aktuellen Page zurückgegeben falls vorhanden
        if (pageLocal?.id !== pageCurrent?.value?.id) {
            const parentPageMeta = getMetadataValue(
                pageCurrent.value?.data?.metadata,
                true
            );
            if (parentPageMeta !== undefined) return parentPageMeta;
        }

        // wenn die Metadaten-Eigenschaft der Site vorhanden ist, wird diese zurückgegeben
        const siteMeta = getMetadataValue(site.value?.data?.metadata, true);
        if (siteMeta !== undefined) return siteMeta;

        // falls die Metadaten-Eigenschaft nicht vorhanden ist, wird undefined zurückgegeben
        return undefined;
    }

    function _getMetadataValueFunction(property, field) {
        return (metadata, inherit) => {
            if (metadata && metadata[field] && metadata[field][property]) {
                const metadataProperty = metadata[field][property];
                if (inherit && metadataProperty.inheritable !== undefined)
                    return metadataProperty.inheritable;
                return metadata[field][property].default;
            }
            return undefined;
        };
    }

    /* Routes */
    const _currentRoute = computed(() => storeBase.router.currentRoute.value);

    const siteRoute = computed(() => {
        return _currentRoute.value.params.site;
    });
    const pageRoute = computed(() => {
        return _currentRoute.value.params.page;
    });
    const detailRoute = computed(() => {
        return _currentRoute.value.params.detail;
    });
    const currentUrl = computed(() => {
        return window.location.href;
    });
    const currentHost = computed(() => {
        return window.location.host;
    });

    /* Firebase */
    const _db = computed(() => {
        return storeBase.db;
    });

    const _sitesCollectionRef = computed(() => {
        return collection(_db.value, 'sites');
    });
    const _siteDocRef = computed(() => {
        return doc(_sitesCollectionRef.value, siteRoute.value);
    });

    const _pagesCollectionRef = computed(() => {
        return collection(_siteDocRef.value, 'pages');
    });
    const _pagesCollectionQueryPublic = computed(() => {
        return query(
            _pagesCollectionRef.value,
            where('access', '==', 'public')
        );
    });
    const _pagesCollectionQueryAuthorized = computed(() => {
        console.log(
            '_pagesCollectionQueryAuthorized, loggedIn:',
            storeAuth.loggedIn,
            'user:',
            storeAuth.user?.uid,
            'email',
            storeAuth.user?.email
        );
        if (!storeAuth.loggedIn)
            console.warn('not logged in user tries to access authorized pages');
        if (isAdmin.value)
            return query(
                _pagesCollectionRef.value,
                where('access', '!=', 'public')
            );
        return query(
            _pagesCollectionRef.value,
            where('access', 'array-contains', storeAuth.user?.uid)
        );
    });

    const getExampleSiteDocRef = computed(() =>
        doc(_sitesCollectionRef.value, 'example')
    );

    /* Pages */
    const pageCurrent = computed(() => {
        return pages.value.find((page) => page.id === pageRoute.value);
    });
    const backgroundImage = computed(
        () =>
            pageCurrent.value?.data?.backgroundImage ||
            site.value?.data?.backgroundImage
    );

    const _fallbackOrder = 9007199254740991;
    const pagesStart = computed(() => {
        return pages.value
            .filter((page) => page.data?.showStart)
            .filter(anonymousFilter)
            .sort(
                (a, b) =>
                    (a.data?.orderStart ?? _fallbackOrder) -
                    (b.data?.orderStart ?? _fallbackOrder)
            );
    });
    const pagesNav = computed(() => {
        return pages.value
            .filter((page) => page.data?.showNav || isAdmin.value)
            .filter(anonymousFilter)
            .sort(
                (a, b) =>
                    (a.data?.orderNav ?? _fallbackOrder) -
                    (b.data?.orderNav ?? _fallbackOrder)
            );
    });

    function anonymousFilter(page) {
        return (
            !storeAuth.loggedIn || !page.data?.onlyAnonymous || isAdmin.value
        );
    }

    /* Mail */
    const _mailField = computed(() => {
        return pageCurrent.value?.data?.form?.mail;
    });

    /* watchers */
    watch(
        [siteRoute, pageRoute, detailRoute],
        () => (errorFormResult.value = null)
    );

    return {
        site,
        pages,
        pagesStart,
        pagesNav,
        pageCurrent,
        backgroundImage,
        pageRoute,
        loading,
        siteRoute,
        lastLoadedSiteRoute,
        loadSite,
        unloadSite,
        addFormResult,
        _pagesCollectionRef,
        _sitesCollectionRef,
        detailRoute,
        currentUrl,
        currentHost,
        getSiteByHost,
        errorFormResult,
        theme,
        getMetadata,
        isTextCenter,
        isBlockEditor,
        isAdmin,
        siteHasContent,
        getExampleSiteDocRef,
    };
});
