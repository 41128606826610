import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useSiteStore } from './SiteStore';
import {
    updateDoc,
    doc,
    CollectionReference,
    getDocs,
    addDoc,
    getDoc,
    deleteDoc,
} from 'firebase/firestore';

export const useEditorStore = defineStore('editor', () => {
    /* Stores */
    const storeSite = useSiteStore();

    /* State */
    const _page = ref(null);
    const _editables = ref(null);
    const edits = ref(null);
    const _editOrderPath = ref(null);
    const editingProperties = ref([]);
    const _editingElements = ref([]);
    const _editOrderAdd = ref(null);
    const _editOrderRemove = ref(null);
    const _preview = ref(false);

    const getId = computed(() => _page.value?.id);
    const _getData = computed(() => _page.value?.data);
    const getEditable = computed(() =>
        _getEditingString(_page.value, _editables.value, _editOrderPath.value)
    );
    const editOrderCanAdd = computed(() => !!_editOrderAdd.value);
    const editOrderCanRemove = computed(() => !!_editOrderRemove.value);

    function isEditing(page, editables, editOrder) {
        return getEditable.value === _getEditingString(page, editables, editOrder);
    }

    /* Edit */
    async function edit(page, path, editables, editOrder, editOrderAdd, editOrderRemove) {
        if (page?.id == null) {
            console.warn('error while starting edit', page);
            return false;
        }
        console.log('edit page:', page, editables, editOrder);
        _page.value = page;
        _editables.value = null;
        _editOrderPath.value = null;
        _editOrderAdd.value = editOrderAdd;
        _editOrderRemove.value = editOrderRemove;

        if (editOrder) return _editOrder(page, path, editOrder);
        else return _editProperties(page, editables);
    }

    async function cancel() {
        console.log('cancel editing');
        _page.value = null;
        _editables.value = null;
        edits.value = null;
        editingProperties.value = [];
        _editingElements.value = [];
        _editOrderPath.value = null;
        _editOrderAdd.value = null;
        _editOrderRemove.value = null;
    }

    async function _editProperties(page, editables) {
        if (page?.id == null || !(editables instanceof Set)) {
            console.warn('error while starting edit properties', page, editables);
            return false;
        }
        console.log('edit page properties:', page, editables);

        edits.value = {};
        _editables.value = editables;
        const _editingProperties = [];

        _editables.value.forEach((editable) => {
            if (!_getData.value || _getData.value[editable] == undefined)
                console.warn(`property ${getId.value}.${editable} does not exist`);
            else {
                _editingProperties.push(editable);
                edits.value[editable] = useHtmlFormat(editable)
                    ? _htmlFormat(_getData.value[editable])
                    : structuredClone(_getData.value[editable]);
            }
        });
        editingProperties.value = _editingProperties;
        return true;
    }

    async function _editOrder(page, path, editOrder) {
        if (page?.id == null || !editOrder) {
            console.warn('error while starting edit order', page, editOrder);
            return false;
        }
        console.log('edit page order:', page, editOrder);

        _editOrderPath.value = editOrder;

        const elements = [];
        const querySnapshot = await getDocs(_getPathRef(path));
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const o = {
                id: doc.id,
                name: data?.name,
                imgage: data?.image,
                disabled: _getDisabled(data),
                order: data[editOrder],
            };
            elements.push(o);
        });

        elements.sort((a, b) => a.disabled - b.disabled || a.order - b.order);
        edits.value = elements;
        _editingElements.value = elements;

        return true;
    }

    /* save */
    async function save(page, path, editables, editOrder) {
        if (getId.value == null) {
            console.warn(`error while saving (${getId.value})`, page);
            return false;
        }
        console.log('save page:', getId.value, editables, editOrder);

        if (editOrder) return _saveOrder(page, editOrder, path);
        else return _saveProperties(page, editables, path);
    }

    async function _saveProperties(page, editables, path) {
        if (getId.value == null || !isEditing(page, editables, null)) {
            console.warn(
                `error while saving properties (${getId.value})`,
                page,
                editables
            );
            return false;
        }
        console.log('save properties:', getId.value, _getPathRef(path));
        await updateDoc(doc(_getPathRef(path), getId.value), edits.value);
        return true;
    }

    async function _saveOrder(page, editOrder, path) {
        if (
            getId.value == null ||
            !isEditing(page, null, editOrder) ||
            !(_editingElements.value instanceof Array) ||
            !(edits.value instanceof Array)
        ) {
            console.warn(
                `error while saving order (${getId.value})`,
                page,
                editOrder,
                _editingElements.value instanceof Array,
                edits.value instanceof Array
            );
            return false;
        }
        const pathRef = _getPathRef(path);
        console.log('save order:', getId.value, editOrder, pathRef);

        edits.value.forEach((e, i) => {
            if (!e.new && e.order !== i) {
                console.log('update', i, e);
                const o = {};
                o[editOrder] = i;
                updateDoc(doc(pathRef, e.id), o);
            }
        });

        _saveOrderAdd(pathRef, editOrder);
        _saveOrderRemove(pathRef, editOrder);

        return true;
    }

    function _saveOrderAdd(pathRef, editOrder) {
        if (!editOrderCanAdd.value) return;
        const toCreate = edits.value.filter((e) => e.new);
        console.log('create', toCreate.length);
        edits.value.forEach((e, i) => {
            if (e.new) {
                console.log('create', i, e);
                _addDocFromCopy(pathRef, editOrder, i);
            }
        });
    }

    async function _addDocFromCopy(pathRef, editOrder, i) {
        const snapshot = await getDoc(_editOrderAdd.value);
        const data = snapshot.data();
        data[editOrder] = i;
        if (editOrderCanRemove.value) data[_editOrderRemove.value] = true;
        await addDoc(pathRef, data);
    }

    function _saveOrderRemove(pathRef) {
        if (!editOrderCanRemove.value) return;
        const toDelete = _editingElements.value
            .filter((e) => e.disabled)
            .filter((e) => !edits.value.find((x) => x.id === e.id));
        console.log(
            'delete',
            toDelete.length,
            toDelete.map((e) => e.id)
        );
        toDelete.forEach((e) => {
            console.log('delete', e);
            deleteDoc(doc(pathRef, e.id));
        });
    }

    /* order edit functions */
    const _newId = computed(() => {
        if (!(edits.value instanceof Array)) return -1;
        const newElements = edits.value.filter((e) => e.new);
        if (newElements.length === 0) return -1;
        return newElements.sort((a, b) => a.id - b.id)[0].id - 1;
    });

    function addElement() {
        if (!(edits.value instanceof Array)) {
            console.warn('error while addElement', edits.value);
        }
        const newId = _newId.value;
        console.log('addElement', newId);
        edits.value.unshift({
            name: 'NewItem',
            id: newId,
            imgage: 'https://cdn-icons-png.flaticon.com/512/8307/8307686.png',
            new: true,
            disabled: true,
            order: newId,
        });
    }

    function removeId(id) {
        if (
            !(edits.value instanceof Array) ||
            !edits.value.find((e) => e.id === id).disabled
        ) {
            console.warn('error while removeId', id, edits.value);
        }
        console.log('removeId', id);
        edits.value = edits.value.filter((e) => e.id !== id);
    }

    async function togglePreview() {
        _preview.value = !_preview.value;
    }

    const showPreview = computed(() => _preview.value);

    /* Helper Functions */
    function formatHtml(html) {
        return _htmlFormat(html);
    }

    function _htmlFormat(html) {
        if (typeof html !== 'string') return html;

        var tab = '    ';
        var result = '';
        var indent = '';

        html.split(/>\s*</).forEach(function (element) {
            if (element.match(/^\/\w/)) {
                indent = indent.substring(tab.length);
            }

            result += indent + '<' + element + '>\r\n';

            if (element.match(/^<?\w[^>^/]*$/) && !element.startsWith('input')) {
                indent += tab;
            }
        });

        return result.substring(1, result.length - 3);
    }

    function _getEditingString(page, editables, editOrder) {
        if (page == null) return null;
        let s = `${page.id}`;
        if (editables != null) s += `.${Array.from(editables).join('.')}`;
        else if (editOrder != null) s += `.${editOrder}`;
        return s;
    }

    function _getFieldEditor(field) {
        // deprecated - use metadata instead
        if (_getData.value.editor) {
            return _getData.value.editor[field];
        }
        if (storeSite.pageCurrent?.data?.editor) {
            return storeSite.pageCurrent.data.editor[field];
        }

        // metadata
        const editor = storeSite.getMetadata('editor', field, _page.value);
        if (editor !== undefined) return editor;

        return null;
    }

    function useRichtextEditor(editable) {
        return _getFieldEditor(editable) === 'rich';
    }

    function useBlockEditor(editable) {
        return _getFieldEditor(editable) === 'block';
    }

    function useHtmlFormat(editable) {
        return _getFieldEditor(editable) === 'html';
    }

    function _getPathRef(path) {
        if (path && path instanceof CollectionReference) return path;
        return storeSite._pagesCollectionRef;
    }

    function _getDisabled(data) {
        if (!editOrderCanRemove.value) return false;
        return !!data[_editOrderRemove.value];
    }

    return {
        isEditing,
        edit,
        edits,
        save,
        cancel,
        getEditable,
        useRichtextEditor,
        useBlockEditor,
        useHtmlFormat,
        editingProperties,
        addElement,
        removeId,
        editOrderCanAdd,
        editOrderCanRemove,
        _editingElements,
        togglePreview,
        showPreview,
        formatHtml,
    };
});
