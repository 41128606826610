<template>
    <v-app id="ybweddingwebsite">
        <NavContainer />
        <MainContainer />
    </v-app>
</template>

<script setup>
import MainContainer from './components/nav/MainContainer.vue';
import NavContainer from './components/nav/NavContainer.vue';
import { useSiteStore } from '@/stores/SiteStore';

const storeSite = useSiteStore();
</script>

<style>
@import url(./fonts/Drawingwithmarkers/font.css);
@import url(./fonts/Nesans-Semi-Bold/stylesheet.css);
@import url(./fonts/Rastanty-Cortez/font.css);
@import url(./fonts/Callista/style.css);
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&family=Oranienbaum&family=Mr+De+Haviland&display=swap');

#ybweddingwebsite,
#ybweddingwebsite div,
#ybweddingwebsite .text-body-1,
#ybweddingwebsite .title,
#ybweddingwebsite .v-toolbar-title,
#ybweddingwebsite p,
#ybweddingwebsite span {
    font-family: v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}

#ybweddingwebsite h1,
#ybweddingwebsite h1 span {
    font-family: v-bind('storeSite?.theme?.fontfamily?.h1'), v-bind('storeSite?.theme?.fontfamily?.title'), v-bind('storeSite?.theme?.fontfamily?.default'),
        sans-serif !important;
    font-size: v-bind('(storeSite?.theme?.fontsize?.h1 ?? 3) + "rem"') !important;
    line-height: v-bind('(storeSite?.theme?.fontsize?.h1 ?? 3) * 1.25 + "rem"') !important;
    margin-bottom: 1.4rem;
    font-weight: v-bind('storeSite?.theme?.fontweight?.h1 ?? storeSite?.theme?.fontweight?.default ?? 600') !important;
}
#ybweddingwebsite h2,
#ybweddingwebsite h2 span {
    font-family: v-bind('storeSite?.theme?.fontfamily?.h2'), v-bind('storeSite?.theme?.fontfamily?.title'), v-bind('storeSite?.theme?.fontfamily?.default'),
        sans-serif !important;
    font-size: v-bind('(storeSite?.theme?.fontsize?.h2 ?? 1.5) + "rem"') !important;
    line-height: v-bind('(storeSite?.theme?.fontsize?.h2 ?? 1.5) * 1.25 + "rem"') !important;
    margin-bottom: 1rem;
    font-weight: v-bind('storeSite?.theme?.fontweight?.h2 ?? storeSite?.theme?.fontweight?.default ?? 600') !important;
}
#ybweddingwebsite h3,
#ybweddingwebsite h3 span {
    font-family: v-bind('storeSite?.theme?.fontfamily?.h3'), v-bind('storeSite?.theme?.fontfamily?.title'), v-bind('storeSite?.theme?.fontfamily?.default'),
        sans-serif !important;
    font-size: v-bind('(storeSite?.theme?.fontsize?.h3 ?? 1.17) + "rem"') !important;
    line-height: v-bind('(storeSite?.theme?.fontsize?.h3 ?? 1.17) * 1.25 + "rem"') !important;
    margin-bottom: 0.8rem;
    font-weight: v-bind('storeSite?.theme?.fontweight?.h3 ?? storeSite?.theme?.fontweight?.default ?? 600') !important;
}
#ybweddingwebsite p,
#ybweddingwebsite p span {
    font-family: v-bind('storeSite?.theme?.fontfamily?.p'), v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
    font-size: v-bind('(storeSite?.theme?.fontsize?.p ?? 1) + "rem"') !important;
    line-height: v-bind('(storeSite?.theme?.fontsize?.p ?? 1) * 1.25 + "rem"') !important;
    margin-bottom: 0.6rem;
    font-weight: v-bind('storeSite?.theme?.fontweight?.p ?? storeSite?.theme?.fontweight?.default ?? 600') !important;
}

#ybweddingwebsite .bg-bgsend {
    color: v-bind('storeSite?.theme?.colors?.onbgsend');
    background-color: v-bind('storeSite?.theme?.colors?.bgsend');
}
#ybweddingwebsite .bg-bgsend.v-btn--disabled {
    color: v-bind('storeSite?.theme?.colors?.onbgsenddisabled');
}
#ybweddingwebsite .bg-bgfocus {
    color: v-bind('storeSite?.theme?.colors?.onbgfocus');
    background-color: v-bind('storeSite?.theme?.colors?.bgfocus');
}
#ybweddingwebsite .v-card-text a,
#ybweddingwebsite .text-link {
    color: v-bind('storeSite?.theme?.colors?.link');
}
#ybweddingwebsite .bg-wishprogress {
    color: v-bind('storeSite?.theme?.colors?.wishprogress');
    background-color: v-bind('storeSite?.theme?.colors?.wishprogress');
}
#ybweddingwebsite .v-card-text a:active {
    color: v-bind('storeSite?.theme?.colors?.linkactive');
}
#ybweddingwebsite .bg-bgnav {
    background-color: v-bind('storeSite?.theme?.colors?.bgnav');
    color: v-bind('storeSite?.theme?.colors?.onbgnav');
}

#ybweddingwebsite .text-normaltext {
    color: v-bind('storeSite?.theme?.colors?.normaltext');
}
#ybweddingwebsite .wish .text-normaltext {
    color: black;
}
#ybweddingwebsite .text-error {
    color: v-bind('storeSite?.theme?.colors?.error');
}
#ybweddingwebsite .v-input--error:not(.v-input--disabled) .v-input__details .v-messages,
#ybweddingwebsite .v-field--error:not(.v-field--disabled) .v-field__outline,
#ybweddingwebsite .v-field--error:not(.v-field--disabled) .v-label.v-field-label {
    color: v-bind('storeSite?.theme?.colors?.error');
}

#ybweddingwebsite .v-row {
    margin-left: 0;
    margin-right: 0;
}

#ybweddingwebsite .bg-bgcontainer {
    background-color: v-bind('storeSite?.theme?.colors?.bgcontainer') !important;
}
#ybweddingwebsite .bg-bgmain {
    background-color: v-bind('storeSite?.theme?.colors?.bgmain');
}
#ybweddingwebsite .bg-bgwishform .bg-bgmain {
    background-color: v-bind('storeSite?.theme?.colors?.bgwishform');
}
#ybweddingwebsite .bg-bgwish {
    background-color: v-bind('storeSite?.theme?.colors?.bgwish');
}

#ybweddingwebsite .v-input label {
    opacity: 1 !important;
}
#ybweddingwebsite .v-selection-control--disabled {
    opacity: 0.6 !important;
}

#ybweddingwebsite .editor textarea {
    font-family: consolas !important;
}

/* Richtext-Editor */
#ybweddingwebsite span.ql-font-arial {
    font-family: 'arial', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Verdana {
    font-family: 'Verdana', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Calibri {
    font-family: 'Calibri', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Futura {
    font-family: 'Futura', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Helvetica {
    font-family: 'Helvetica', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Roboto {
    font-family: 'Roboto', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Consolas {
    font-family: 'Consolas', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-monospace {
    font-family: 'monospace', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Drawingwithmarkers {
    font-family: 'Drawingwithmarkers', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Theos {
    font-family: 'Theos', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Rastanty-Cortez {
    font-family: 'Rastanty Cortez', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Callista {
    font-family: 'Callista Regular', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Oranienbaum {
    font-family: 'Oranienbaum', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Antic-Didone {
    font-family: 'Antic Didone', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Nesans-Semi-Bold {
    font-family: 'Nesans Semi Bold', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}
#ybweddingwebsite span.ql-font-Mr-De-Haviland {
    font-family: 'Mr De Haviland', v-bind('storeSite?.theme?.fontfamily?.default'), sans-serif !important;
}

#ybweddingwebsite span.ql-size-huge {
    font-size: xxx-large;
}
#ybweddingwebsite span.ql-size-large {
    font-size: xx-large;
}
#ybweddingwebsite span.ql-size-small {
    font-size: small;
}

#ybweddingwebsite .ql-align-right {
    text-align: right !important;
}
#ybweddingwebsite .ql-align-center {
    text-align: center !important;
}
#ybweddingwebsite .ql-align-left {
    text-align: left !important;
}
#ybweddingwebsite .ql-align-justify {
    text-align: justify !important;
}
</style>
