<template>
    <v-row v-for="editable in storeEditor.editingProperties" :key="editable">
        <v-col cols="12">
            <EditorHtmlField :editable="editable" v-model="storeEditor.edits[editable]" />
        </v-col>
        <v-col
            v-if="
                storeEditor.showPreview &&
                (storeEditor.useRichtextEditor(editable) ||
                    storeEditor.useBlockEditor(editable) ||
                    storeEditor.useHtmlFormat(editable) ||
                    editable === 'image')
            "
            cols="12"
        >
            <default-card>
                <default-card-text>
                    <div
                        class="content text-normaltext"
                        :class="{
                            'text-center': storeSite.isTextCenter(editable),
                        }"
                    >
                        <ContentBlockViewer
                            v-if="
                                storeEditor.useBlockEditor(editable) &&
                                typeof storeEditor.edits[editable] === 'object'
                            "
                            :blockContent="storeEditor.edits[editable]"
                        />
                        <ContentHtmlContainer
                            v-else-if="
                                storeEditor.useRichtextEditor(editable) ||
                                storeEditor.useHtmlFormat(editable)
                            "
                            :htmlContent="storeEditor.edits[editable]"
                            :siteId="$route.params.site"
                        />
                        <v-img
                            v-else-if="isImage(editable)"
                            :src="storeEditor.edits[editable]"
                            height="200px"
                        ></v-img>
                    </div>
                </default-card-text>
            </default-card>
        </v-col>
        <v-col v-else-if="storeEditor.showPreview && !!debug" cols="12">
            <pre>{{ storeEditor.edits[editable] }}</pre>
        </v-col>
    </v-row>
</template>

<script setup>
import { useEditorStore } from '@/stores/EditorStore';
import { useSiteStore } from '@/stores/SiteStore';
import EditorHtmlField from './EditorHtmlField.vue';
import ContentBlockViewer from '../pages/contentPage/ContentBlockViewer.vue';
import ContentHtmlContainer from '../pages/contentPage/ContentHtmlContainer.vue';
import DefaultCardText from '@/components/componentunits/DefaultCardText.vue';
import DefaultCard from '@/components/componentunits/DefaultCard.vue';

const storeEditor = useEditorStore();
const storeSite = useSiteStore();

const debug = false;

function isImage(editable) {
    return (
        (editable === 'image' || editable === 'src') &&
        typeof storeEditor.edits[editable] === 'string'
    );
}
</script>
