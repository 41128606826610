<template>
    <div v-for="block in blockContent?.blocks" :key="block.id">
        <div v-if="block.type === 'header'">
            <component :is="'h' + block.data.level">
                <ContentHtmlContainer
                    :htmlContent="block.data.text"
                    :siteId="$route.params.site"
                    :inline="true"
                />
            </component>
        </div>
        <div v-else-if="block.type === 'paragraph'">
            <p>
                <ContentHtmlContainer
                    :htmlContent="block.data.text"
                    :siteId="$route.params.site"
                    :inline="true"
                />
            </p>
        </div>
        <div v-else-if="block.type === 'image'">
            <ImageContainer :image="getImage(block.data)" />
        </div>
        <div v-else-if="block.type === 'list'">
            <component
                :is="block.data.style === 'ordered' ? 'ol' : 'ul'"
                style="padding-left: 25px"
                :start="block.data.meta.start"
                :type="getCounterType(block.data.meta.counterType)"
            >
                <li v-for="item in block.data.items" :key="item.id">
                    <ContentHtmlContainer
                        :htmlContent="item.content"
                        :siteId="$route.params.site"
                        :inline="true"
                    />
                </li>
            </component>
        </div>
        <div v-else-if="block.type === 'code'">
            <pre>{{ block.data.code }}</pre>
        </div>
        <div v-else-if="block.type === 'divider'">
            <hr />
        </div>
        <div v-else-if="block.type === 'raw'" class="blockEditor-raw">
            <ContentHtmlContainer
                :htmlContent="block.data.html"
                :siteId="$route.params.site"
            />
        </div>
    </div>
    <pre v-if="debug">{{ blockContent }}</pre>
</template>

<script setup>
import ContentHtmlContainer from './ContentHtmlContainer.vue';
import ImageContainer from '../../image/ImageContainer.vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    blockContent: {
        type: Object,
        required: true,
    },
});

function getImage(blockData) {
    return {
        src: blockData.url,
        alt: blockData.caption,
    };
}

function getCounterType(type) {
    switch (type) {
        case 'numeric':
            return '1';
        case 'lower-alpha':
            return 'a';
        case 'lower-roman':
            return 'i';
        case 'upper-alpha':
            return 'A';
        case 'upper-roman':
            return 'I';
        default:
            return '1';
    }
}

const debug = false;
</script>
