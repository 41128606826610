<template>
    <div class="site">
        <v-container
            v-if="debug || (storeSite.isAdmin && storeSite.siteHasContent)"
            fluid
            class="px-0 -px-md-4"
        >
            <v-row v-if="debug">
                <v-col>
                    <default-card>
                        <default-card-text>
                            <div>
                                <p>siteRoute: {{ storeSite.siteRoute ?? 'null' }}</p>
                                <p>siteHasContent: {{ storeSite.siteHasContent }}</p>
                                <p>pageRoute: {{ storeSite.pageRoute ?? 'null' }}</p>
                                <p>
                                    pageCurrent:
                                    {{ storeSite.pageCurrent?.data?.name ?? 'null' }}
                                </p>
                                <p>
                                    showStart:{{
                                        storeSite.pageCurrent?.data?.showStart ?? false
                                    }}
                                </p>
                                <p>
                                    onlyAnonymous:
                                    {{
                                        storeSite.pageCurrent?.data?.onlyAnonymous ??
                                        false
                                    }}
                                </p>
                            </div>
                        </default-card-text>
                    </default-card>
                </v-col>
            </v-row>
            <v-row v-if="storeSite.isAdmin && storeSite.siteHasContent">
                <v-col>
                    <EditorContainer
                        :page="storeSite.site"
                        :path="storeSite._sitesCollectionRef"
                        :editableProperties="['theme']"
                        editgroup="Site Theme"
                    />
                </v-col>
            </v-row>
        </v-container>

        <LoaderCircular v-if="storeSite.loading" class="mt-5" />
        <SiteStart
            v-else-if="
                storeSite.siteHasContent &&
                (storeSite.pageRoute == null ||
                    (storeSite.pageCurrent?.data?.showStart &&
                        !storeSite.pageCurrent?.data?.onlyAnonymous))
            "
        />
        <PageContainer
            v-else-if="storeSite.siteHasContent && storeSite.pageCurrent != null"
            :page="storeSite.pageCurrent"
        />
        <div v-else>404</div>
    </div>
</template>

<script setup>
import SiteStart from './SiteStart.vue';
import { useSiteStore } from '@/stores/SiteStore';
import PageContainer from '@/components/pages/PageContainer.vue';
import LoaderCircular from '../componentunits/LoaderCircular.vue';
import DefaultCardText from '@/components/componentunits/DefaultCardText.vue';
import DefaultCard from '@/components/componentunits/DefaultCard.vue';
import EditorContainer from '@/components/editor/EditorContainer.vue';

const debug = false;

const storeSite = useSiteStore();
</script>
