<template>
    <v-row>
        <v-col cols="12">
            <draggable
                v-model="storeEditor.edits"
                item-key="id"
                class="list-group"
                ghost-class="ghost"
            >
                <template #item="{ element }">
                    <div class="list-group-item">
                        <v-row no-gutters>
                            <v-col cols="3">{{ element.name }}</v-col>
                            <v-col cols="4">
                                <img
                                    v-if="element.imgage"
                                    :src="element.imgage"
                                />
                            </v-col>
                            <v-col cols="5" class="text-right">
                                <v-btn
                                    v-if="
                                        element.disabled &&
                                        storeEditor.editOrderCanRemove
                                    "
                                    @click="
                                        () => storeEditor.removeId(element.id)
                                    "
                                >
                                    <v-icon icon="mdi-delete"></v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </template>

                <template #header v-if="storeEditor.editOrderCanAdd">
                    <div class="list-group-item btn">
                        <v-btn @click="storeEditor.addElement">
                            Hinzufügen<v-icon icon="mdi-plus"></v-icon>
                        </v-btn>
                    </div>
                </template>
            </draggable>
        </v-col>
    </v-row>
</template>

<script setup>
import { useEditorStore } from '@/stores/EditorStore';
import draggable from 'vuedraggable';

const storeEditor = useEditorStore();
</script>

<style scoped>
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin: 12px;
    border-radius: 0.25rem;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item.btn {
    background: transparent;
    border: 1px solid transparent;
}
.list-group-item img {
    max-height: 40px;
    max-width: 60px;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.not-draggable {
    cursor: no-drop;
}
</style>
