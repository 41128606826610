<template>
    <div>
        <label>{{ editable }}</label>
        <v-checkbox v-if="type === 'boolean'" v-model="model" />
        <v-text-field
            v-else-if="type === 'number'"
            v-model.number="model"
            hide-details
            single-line
            type="number"
        />
        <EditorHtmlFieldRichtext
            v-else-if="type === 'string' && storeEditor.useRichtextEditor(editable)"
            v-model="model"
        />
        <EditorHtmlFieldBlock
            v-else-if="storeEditor.useBlockEditor(editable)"
            v-model="model"
        />
        <div v-else-if="type === 'string'">
            <v-color-picker
                v-if="isColorString(model)"
                v-model="model"
                hide-inputs
            />
            <v-textarea class="editor" v-model="model" :rows="defaultHeight" />
        </div>
        <div v-else-if="type === 'object'" class="border border-thin rounded pa-2">
            <EditorHtmlField
                v-for="key in modelKeys"
                :key="key"
                v-model="model[key]"
                :editable="key"
            />
        </div>
    </div>
</template>

<script setup>
import { defineModel, watch, computed, onMounted } from 'vue';
import EditorHtmlFieldRichtext from './EditorHtmlFieldRichtext.vue';
import EditorHtmlFieldBlock from './EditorHtmlFieldBlock.vue';
import { useEditorStore } from '@/stores/EditorStore';

const model = defineModel();

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    editable: { type: String, required: true },
});

const storeEditor = useEditorStore();

let initType = '';
onMounted(() => (initType = typeof model.value));
const type = computed(() => typeof model.value);
const defaultHeight = computed(() =>
    type.value === 'string' && model.value.indexOf('\n') > -1 ? 4 : 1
);
const modelKeys = computed(() => {
    if (type.value === 'object') {
        return Object.keys(model.value).sort();
    }
    return [];
});

watch(model, () => {
    if (initType === 'number' && !model.value) model.value = 0;
});

function isColorString(str) {
    if (typeof str !== 'string') return false;
    return RegExp(/^#([0-9a-fA-F]{3,4}){1,2}$/).test(str);
}

const debug = false;
</script>
