<template>
        <span @click="redirect($event)" v-html="content"></span>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    to: {
        type: String,
        required: true,
    },
    content: {
        type: String,
        required: true,
    },
    routerPush: {
        type: Function,
        required: true,
    },
});

function redirect(e) {
    console.log('redirect to', props.to);
    e.preventDefault();
    props.routerPush(props.to);
}
</script>
