import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { db, analytics } from './firebase';
import vuetify from './plugins/vuetify';


const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
    store.db = markRaw(db);
    store.analytics = markRaw(analytics);
});

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(vuetify);
app.mount('#app');
